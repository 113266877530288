* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-align: none;
}

body {
  background-color: white;
  min-height: 100%;
  width: 100%;
  font-family: "Nunito", sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

/* top of page */
.topofpage {
  max-width: 800px;
  margin: auto;
  margin-top:50px;
  padding: 30px;
}


/* Title Section */
.title {
  background-color: white;
  height: 100%;
  width: 100%;
  max-width:800px;
  margin: auto;
  top-margin: 50px;
}

.title h1 {
/*  position: absolute;
  height: 1.27em;
  left: 0.94em;
  top: 1.98em;
  right: 1em;
*/
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 2.25em;
  line-height: 115.6%;
  color: #9b51e0;
}

.title h2 {
/*  position: absolute;*/
/*  height: 2.25em;
  left: 2.5em;
  top: 8em;
  right: 1em;*/

  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 137%;
  width: 80%;

  color: rgba(0, 0, 0, 0.43);
}

/* Form Section */
.form {
/*  position: absolute;
  top: 12.25em;
  width: 80%;
  max-width: 600px;
  left: 2.5em;
  display: grid;
  grid-template-columns: 3fr 1fr;*/
}

.formInput {
  border: 2px solid #d6dadf;
  box-sizing: border-box;
  border-radius: 3px;
  height: 2.5em;
  width: 80%;
  font-size: 1em;
  margin-top: 30px;
}

.form button {
  background-color: #9b51e0;
  color: white;
  box-shadow: 0px 3px 0px #6922ab;
  border-radius: 0px 4px 4px 0px;
  height: 2.3em;
  font-size: 1em;
  width: 20%;
/*  min-width: 100px;*/
  margin-top: -40px;
  float: right;
}

.form button:hover {
  background-color: #482567;
}

.form button p {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 171%;
  text-align: center;
  color: #ffffff;
}

#poweredBy {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

#poweredBy p {
/*  position: absolute;*/
  top: 32em;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #9b51e0;
  margin: 30px;
}

/* Bottom of Page */
.bottomOfPage {
/*  position: absolute;*/
/*  top: 329px;*/
  width: 100%;
}

/* Real Clinic Result */
#knownClinicResult {
  position: relative;
  width: 100%;
}

#knownClinicAlert {
  background-color: #e5f4f4;
  height: 3.625em;
  position: relative;
  display: flex;
  width: 100%;
}

#knownClinicAlert h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.375em;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: #2a4354;
  width: 100%;
}
#knownClinicCard {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  width: 90%;
  margin: 5%;
  padding-bottom: 1.28em;
  display: flex;
  flex-direction: column;
}

#realClinicIcon {
  width: 80px;
  height: 23px;
  padding: 1em;
  align-self: flex-end;
}

#knownClinicCard h3 {
  padding-top: 1em;
  padding-left: 1em;
  position: relative;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.39em;

  color: #2a4354;
}

#knownClinicCard h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;

  display: flex;
  align-items: center;

  color: #7e42b6;
  padding-left: 1.26em;
  padding-top: 1.26em;
}

#phoneAndWebsite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#knownClinicCard h5 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.57em;

  display: flex;
  align-items: center;
  padding-left: 1.8em;
  padding-top: 1em;
  color: #3860fc;
  margin-right: 3em;
}

#knownClinicCard a {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.57em;

  display: flex;
  align-items: center;
  color: #3860fc;
  margin-right: 3em;
}

/* Fake Clinic Result */
#fakeClinicResult {
  position: relative;
  width: 100%;
}

#fakeClinicAlert {
  background-color: #fdedf2;
  height: 7.625em;
  position: relative;
  display: flex;
  width: 100%;
  display: grid;

  text-align: center;
}

#fakeClinicAlert h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.375em;
  text-align: center;
  color: #e13773;
  width: 100%;
  padding-top: 1.5em;
}

#fakeClinicAlert h5 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1.357em;
  color: rgba(0, 0, 0, 0.43);
  width: 100%;
}

#fakeClinicAlert a {
  font-weight: 600;
  text-decoration: underline;
}

#fakeClinicCard {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  width: 90%;
  margin: 5%;
  padding-bottom: 1.28em;
  display: flex;
  flex-direction: column;
}

#fakeClinicIcon {
  width: 64px;
  height: 23px;
  padding: 1em;
  align-self: flex-end;
}

#fakeClinicCard h3 {
  padding-left: 1em;
  position: relative;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125em;
  line-height: 1.39em;

  color: #2a4354;
}

#fakeClinicCard h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;

  display: flex;
  align-items: center;

  color: #7e42b6;
  padding-left: 1.26em;
  padding-top: 1.26em;
}

#fakeClinicCard h5 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1.57em;

  display: flex;
  align-items: center;
  padding-left: 1.28em;
  padding-top: 1em;
  color: #3860fc;
}

/* Unknown Clinic Result */
#unknownClinicResult {
  position: relative;
  width: 100%;
}

#unknownClinicAlert {
  background-color: #faf6e7;
  height: 8.125em;
  position: relative;
  display: grid;

  text-align: center;
  width: 100%;
}

#unknownClinicAlert h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;

  display: flex;
  align-items: center;
  text-align: center;
  justify-self: center;
  color: #b9862b;
  padding-top: 1.26em;
}

#unknownClinicAlert h5 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1.57em;
  text-align: center;
  justify-self: center;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.43);
}

#unknownClinicForm {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  width: 90%;
  margin: 5%;
  padding-bottom: 1.28em;
  text-align: center;
}

/* Information Section */
.footerBackground {
  background-color: #E8ECFE;
  padding-bottom: 5em;
}

.footerInformation {

  margin: auto;
  max-width: 800px;
  padding: 30px 15px;

}

.footerInformation h3 {
  margin-top: 1.63em;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 112.6%;

  color: #7e42b6;
}

.footerInformation p {
  margin-top: 2em;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 133%;
  color: #2a4354;
}

.footerInformation hr {
  font-size: 0.9375em;
  border: 1px solid #7e42b6;
}

.footerInformation a {
  color: #3860fc;
  font-weight: 600;
}

#pullQuote {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 137.5%;
  color: #7e42b6;
}

#pullQuote a {
  color: #7e42b6;
  text-decoration: underline;
}
